<template>
  <loader-view v-if="loading" />

  <router-view v-else :properties="properties" :property="property" />
</template>

<script>
import { request } from "@/services";
export default {
  data() {
    return {
      loading: true,
      properties: [],
      property: null,
    };
  },

  beforeMount() {
    this.getContracts();
  },

  watch: {
    ["$route.params"]() {
      this.handleProperty();
    },
  },

  methods: {
    async getContracts() {
      try {
        const payload = {
          method: "getAvaliablesContratos",
          page: 1,
        };

        await request(payload).then((res) => {
          this.properties = res.contratos.map((el) => {
            return {
              id: el.id,
              title: el.nomeContrato,
              resources: el.listaBeneficios,
              location: el.politicaOperacional,
              sharesSold: el.cotaVendida,
              description: el.objetivo,
              comment: el.descRendimentoContrato,
              minValue: el.minDeposito,
              maxValue: el.maxDeposito,
              images: el.imagens,
              contract: el.linkPDF,
            };
          });

          if (this.$route.params.id) this.handleProperty();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleProperty() {
      const property = this.properties.find(
        (el) => el.id === this.$route.params.id
      );

      if (!property) return this.$router.push({ path: "/investment" });

      this.property = property;
    },
  },
};
</script>

<style></style>
